<template>
  <div>
    <div class="content-body">
      <!-- content home -->
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-sm-12 col-lg-12">
            <div
              class="row align-items-center justify-content-center text-center mb-5 mt-5"
            >
              <div class="col-12 col-sm-12 col-lg-9">
                <div class="titel-Invest">
                  Let’s
                  <span class="sub-Invest"
                    >Invest
                    <img class="img-fluid" src="../assets/images/spaceship.svg"
                  /></span>
                </div>
                <div class="titel-Invest-sub mb-5">
                  with <span class="sub-Invest">GROWTH</span>
                </div>
                <div class="titel-home">Investing Made Simple</div>
                <div class="sub-titel-home mb-3">
                  Starting from 1 USD, Growth gives investors real-time access to
                  invest in over a thousand stocks listed on the U.S. stock
                  exchanges.
                </div>
                <!-- <div class="mb-5">
                  <span class="text-color-number">9,999 </span
                  >
                  <span class="text-joined"
                    >investors had joined the waitlist.</span
                  >
                </div> -->
                <router-link to="/register">
                  <div class="btn btn-start btn-lg mb-3">
                    Join Waiting List
                  </div>
                </router-link>
              </div>
            </div>

            <div
              class="row mb-5 align-items-center justify-content-center mb-5"
            >
              <div class="col col-lg-9">
                <div class="card-content">
                  <div class="card-body">
                    <div class="main-title-card">
                      <font class="title-card"> What if …?</font>
                    </div>
                    <div class="title-card-primary mb-5">
                      You invested in these stocks 5 years ago.
                    </div>
                  </div>
                  <div
                    class="row align-items-center justify-content-center text-center padding-logo mb-5"
                  >
                    <div
                      class="col col-md-2 col-logo"
                      v-for="data in value"
                      :key="data.logo_url"
                    >
                      <div>
                        <img
                          class="img-fluid img-main mb-3"
                          :src="data.logo_url"
                        />
                        <br>
                        <b-badge pill class="banner-value"
                          >$
                          {{
                            data.ratio * slider | formatNumberWithMark(0,0)
                          }}</b-badge
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="row align-items-center justify-content-center mt-5"
                  >
                    <div class="col col-md-10 col-logo">
                      <div class="card-body">
                        <vue-slider
                          type="range"
                          min="1"
                          max="1000"
                          v-model="slider"
                          :tooltip-formatter="formatter1"
                          :tooltip="'always'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-3 sub-titel-home">
                    Initial Funds
                  </div>

                  <div class="text-as mr-5">As of {{value[0].date}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end content home -->

    <!-- content-whygrowth -->
    <div class="bg-white">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-auto col-md-5 col-content">
            <div class="whygrowth">Why Growth?</div>
            <div class="title-content">😎 Real Ownership</div>
            <div class="text-content mb-3">
              Every share listed on Growth is a real asset. You are the
              beneficial owner of the stocks.
            </div>
          </div>
          <div class="col-auto col-md-4 text-end col-content-100">
            <img
              class="img-fluid"
              src="../assets/images/mockup-mobile/growthmock.png"
              style="width: 320px;"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end content-whygrowth -->

    <!-- content-fraction -->
    <div class="bg-dark">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-auto col-md-4 col-content-100">
            <img
              class="img-fluid"
              src="../assets/images/mockup-mobile/growthmock2.png"
              style="width: 320px;"
            />
          </div>
          <div class="col-auto col-md-5">
            <div class="title-content">⭐️ A Fraction of Future</div>
            <div class="text-content mb-3">
              Growth offers fractional trading. You can invest as little money
              as you want to.
            </div>
            <div class="text-content mb-5">
              Start growing wealth for your future goals.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end content-fraction -->

    <!-- content-Investing -->
    <div class="bg-white">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-auto col-md-5 col-content">
            <div class="title-content">✅ Investing Made Simple</div>
            <div class="text-content">• One-Day open trading account.</div>
            <div class="text-content">
              • Real-time data from many US. Stock exchanges
            </div>
            <div class="text-content">• Protected up to 500,000 USD by SIPC</div>
          </div>
          <div class="col-auto col-md-4 text-end col-content-100">
            <img
              class="img-fluid"
              src="../assets/images/mockup-mobile/growthmock3.png"
              style="width: 320px;"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end content-Investing -->
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  components: {
    VueSlider,
  },
  data() {
    return {
      slider: 500,
      formatter1: (v) => `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      value: [
        {
          symbol: "TSLA",
          logo_url:
            "https://storage.radarspoint.com/radars-point-images/plan/tesla.jpg",
          bg_url:
            "https://storage.radarspoint.com/radars-point-images/plan/background/Head_Tesla.jpg",
          ratio: 14.44,
          date: "30 May 2022",
        },
        {
          symbol: "AAPL",
          logo_url:
            "https://storage.radarspoint.com/radars-point-images/plan/apple.jpg",
          bg_url:
            "https://storage.radarspoint.com/radars-point-images/plan/background/Head_Apple.jpg",
          ratio: 3.45,
          date: "30 May 2022",
        },
        {
          symbol: "NFLX",
          logo_url:
            "https://storage.radarspoint.com/radars-point-images/plan/netflix.jpg",
          bg_url:
            "https://storage.radarspoint.com/radars-point-images/plan/background/Head_Netflix.jpg",
          ratio: 1.3,
          date: "30 May 2022",
        },
      ],
    };
  },
  methods: {
  formatNumber (num) {
    return parseFloat(num).toFixed(0)
  },
}
};
</script>
<style>
.main-title-card {
  color: #232323;
  font-size: 36px;
  text-align: center;
  padding-top: 50px;
}

@media only screen and (max-width: 578px) {
  .content-body {
    min-height: calc(100vh - 160px);
    padding: 50px 0;
  }
}
.vue-slider-dot-tooltip-inner {
  font-size: 22px;
  white-space: nowrap;
  color: #3cd089;
  padding: 6px 8px;
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  transform: scale(0.9);
  transition: transform 0.3s;
  text-decoration-line: underline;
}
.vue-slider-process {
  background-color: #ededed;
  border-radius: 15px;
  transition: background-color 0.3s;
}
.vue-slider:hover .vue-slider-process {
  background-color: #ededed;
}
</style>
